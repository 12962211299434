import * as React from "react";
import Header from "../components/Header";
import { withPrefix } from "gatsby";

import "../scss/style.scss";
import HomePage from "../components/home/HomePage";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";

// markup
const IndexPage = () => {
  return (
    <main>
      <title>Société de sécurité spécialisée dans votre protection</title>
      <Header />
      <HomePage />
    </main>
  );
};

export default IndexPage;
