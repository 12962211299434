import * as React from "react";
import { Link } from "gatsby";
import Service from "../services/Service";
import ServiceData from "../../data/ServicesData";

// markup
const HomePageServices = () => {
  return (
    <div className="container pt-8 pb-8 pb-md-14 pt-md-14 bg-white">
      <div className="row justify-content-start list-services">
        <div className="col-12">
          <h2 className="text-center mb-3">Nos Prestations</h2>
        </div>
        {ServiceData.map((service) => (
          <Service {...service} key={service.weight}/>
        ))}
        <div className="col-12 text-center">
          <a className="button button-primary" href="/services">
            Nos services
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomePageServices;
