import * as React from "react";
import { Link } from "gatsby";
import background from "../../images/main-page.jpg";
import HomePageSubheading from "./HomePageSubheading";
import HomePageServices from "./HomePageServices";
import Difference from "./Difference";
import Footer from "../Footer";

const HomePageStyle: object = {
  backgroundImage: `url(${background})`,
};

// markup
const HomePage = () => {
  return (
    <>
      <div className="wrapper-parallax">
        <section className="main-parallax">
          <div
            className="hero-image hero-image-fullscreen"
            style={HomePageStyle}
          >
            <div className="container">
              <div className="hero-text">
                <span className="hero-section"></span>
                <h1>AM Sécurité</h1>
                <p>
                  Votre protection, notre priorité.{" "}
                  <a href="/contact" className="tel">
                    Contactez-nous !
                  </a>
                </p>
              </div>
              <div className="hero-down">
                <a href="#home">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="60"
                    height="60"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 898.75 533.751"
                      transform="matrix(.69 0 0 .69 15.5 15.5)"
                      fill="#fff"
                    >
                      <path d="M224.687 309.061L0 84.372l42.186-42.186L84.372 0l182.815 182.811L450 365.622l182.5-182.496L815 .63l41.874 41.874L898.75 84.38 674.062 309.065 449.373 533.751 224.687 309.06z" />
                    </svg>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="secondary-parallax">
          <HomePageSubheading />
          <HomePageServices />
          <Difference />
          <Footer />
        </section>
      </div>
    </>
  );
};

export default HomePage;
