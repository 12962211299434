import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import ContactUs from "../buttons/ContactUs";

// markup
const Difference = () => {
  return (
    <div
      id="home"
      className="strip bg-secondary bg-cover bg-position-top bg-overlay"
    >
      <div className="container pt-8 pb-8 pb-md-12 pt-md-12">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 text-center text-white">
            <h2>Pourquoi nous choisir ?</h2>
            <p>
              Notre mission est de vous offrir la meilleure protection possible
              pour que vous soyez l'esprit tranquille et en capacité de vos
              concentrer sur le plus important : votre business.
            </p>
            <ContactUs />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Difference;
