import * as React from "react";
import { Link } from "gatsby";
import background from "../../images/work.jpg";

const HomePageSubheadingStyle: object = {
  backgroundImage: `url(${background})`,
};

// markup
const HomePageSubheading = () => {
  return (
    <div
      id="home"
      className="strip bg-cover bg-secondary bg-position-top bg-overlay"
      style={HomePageSubheadingStyle}
    >
      <div className="container pt-17 pb-17">
        <div className="row">
          <div className="col-12 col-md-6 offset-md-6 text-primary">
            <h2 className="text-capitalize">Exigez un service de qualité</h2>
            <p>
              Fort de plusieurs années d'expériences et mobile en Île-de-France,
              nous avons fait de votre sécurité notre mission. Nous différentes
              compétences vous permettront de pouvoir vous concentrer sur votre
              business l'esprit tranquille.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageSubheading;
